<template>
  <div id="app">
    <transition name="overflow-fade">
      <div
        v-if="$root.ready && overflow"
        class="ready-overflow"
        @click="openWebsite()"
      >
        <div class="w-100 text-center">
          <div class="title">Undangan {{ title }}</div>
          <span>from</span>
          <div class="name mb-5">{{ wedding.man.surname }} & {{ wedding.woman.surname }} </div>

          <button
            class="btn btn-outline-primary text-uppercase"
            @click="openWebsite()"
          >
            Buka Undangan
          </button>
        </div>
      </div>
    </transition>

    <AGWAudio
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
      ref="audio"
    />
    <AGWHero
      overlay
      :images="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="agw-content bg-white"
      :style="{ 'margin-top': `${$root.hero.height}px` }"
    >
      <AGWIntro id="intro" />

      <AGWPawiwahan id="pawiwahan" class="bg-blue-light py-5" />
      <AGWMepandes id="mepandes" class="pad-y-60" :mepandes="mepandes" />

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="py-5" />
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <section class="footer bg-white pad-y-20" ref="footer">
      <div class="">
        <img src="https://ik.imagekit.io/ceremonyku/partner/saikubera.png?tr=w-300" class="mx-auto" style="max-width: 125px" />
      </div>
      <div class="w-full flex justify-center mt-2 mb-2">
        <a
          href="https://www.instagram.com/saikuberaphoto/"
          target="_blank"
          class="mx-2"
          ><svg
            id="instagram"
            xmlns="http://www.w3.org/2000/svg"
            width="26.694"
            height="26.694"
            viewBox="0 0 26.694 26.694"
          >
            <path
              id="Path_443"
              data-name="Path 443"
              d="M9.741,2H20.953a7.747,7.747,0,0,1,7.741,7.741V20.953a7.741,7.741,0,0,1-7.741,7.741H9.741A7.747,7.747,0,0,1,2,20.953V9.741A7.741,7.741,0,0,1,9.741,2M9.474,4.669a4.8,4.8,0,0,0-4.8,4.8V21.22a4.8,4.8,0,0,0,4.8,4.8H21.22a4.8,4.8,0,0,0,4.8-4.8V9.474a4.8,4.8,0,0,0-4.8-4.8H9.474m12.88,2A1.668,1.668,0,1,1,20.686,8.34a1.668,1.668,0,0,1,1.668-1.668m-7.007,2a6.673,6.673,0,1,1-6.673,6.673,6.673,6.673,0,0,1,6.673-6.673m0,2.669a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
              transform="translate(-2 -2)"
              fill="#283a60"
            ></path></svg
        ></a>
        <a href="https://wa.me/6281999061054" target="_blank" class="mx-2"
          ><svg
            id="whatsapp"
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
          >
            <path
              id="Path_445"
              data-name="Path 445"
              d="M21.175,17.548c.325.169.533.26.6.39a2.559,2.559,0,0,1-.273,1.534,3.166,3.166,0,0,1-2.21,1.456c-.6.026-.611.468-3.848-.949s-5.187-4.875-5.343-5.1A6.3,6.3,0,0,1,8.9,11.49a3.543,3.543,0,0,1,1.235-2.652.969.969,0,0,1,.884-.338h.611c.2,0,.468-.078.715.585l.9,2.431a.593.593,0,0,1,.013.572l-.351.533-.507.546c-.156.156-.338.325-.156.65a9.648,9.648,0,0,0,1.716,2.314,8.59,8.59,0,0,0,2.535,1.69c.312.182.507.156.7-.052l1.053-1.222c.247-.325.455-.247.754-.143l2.171,1.144M15,2A13,13,0,1,1,8.045,25.985L2,28l2.015-6.045A13,13,0,0,1,15,2m0,2.6A10.4,10.4,0,0,0,6.5,20.993L5.25,24.75,9.007,23.5A10.4,10.4,0,1,0,15,4.6Z"
              transform="translate(-2 -2)"
              fill="#283a60"
            ></path></svg
        ></a>
      </div>
      <div class="font-weight-lighter">
        &copy; {{ $moment().format("YYYY") }}. Made & supported by
        <a href="https://ceremonyku.com">ceremonyku.com</a>.
      </div>
    </section>

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/ceremonyku/deka-dewi/DSB_3236_cetak_50x75_resized.jpg?tr=w-800";
const ImageHero2 =
  "https://ik.imagekit.io/ceremonyku/deka-dewi/DSB_3425_resized.jpg?tr=w-800";
const ImageHero3 =
  "https://ik.imagekit.io/ceremonyku/deka-dewi/DSB_3380_resized.jpg?tr=w-800";

import Audio from "@/assets/audio/soundtrack.mp3";

import AGWHero from "@/components/Hero/HeroTwo";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery/GalleryOne";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Attendance from "@/components/Attendance";
import Himbauan from "./components/Himbauan.vue";

import { setInterval } from "timers";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
  },
  data() {
    return {
      title: "Pawiwahan",
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      location:
        "Jl. Wr. Supratman, Gg III No. 3 Br. Tohpati Kesiman, Kertelangu, Denpasar Timur",
      direction:
        "https://www.google.com/maps/dir//-8.6361055,115.2549667/@-8.6361055,115.252778,17z/data=!4m2!4m1!3e0?hl=en",
      time: {
        start: this.$moment("2021-04-02 10:00"),
        end: "Selesai",
      },
      wedding: {
        man: {
          name: "I Gede Surya Adi Saputra",
          surname: "Deka",
          parents: "I Gede Nyoman Suparta & Ni Luh Made Partini",
          description: "Putra pertama",
          address:
            "Jl. Wr. Supratman, Gg III No. 3 Br. Tohpati Kesiman, Kertelangu, Denpasar Timur",
          photo:
            "https://ik.imagekit.io/ceremonyku/deka-dewi/DSB_3387_resized.jpg?tr=w-573,h-717,fo-custom,cm-extract",
        },
        woman: {
          name: "Ni Putu Puspita Dewi",
          surname: "Dewi",
          parents: "I Made Surma & Sayu Putu Suintari",
          address: "Br. Peken Serangan",
          description: "Putri pertama",
          photo:
            "https://ik.imagekit.io/ceremonyku/deka-dewi/DSB_3403_resized.jpg?tr=w-558,h-698,fo-custom,cm-extract",
        },
      },
      mepandes: [
        // {
        //   name: 'I Gusti Ngurah Agung Teguh Aksama Putra',
        //   // image: 'https://ik.imagekit.io/ceremonyku/iyan-tini/SKP_4414e_XA6Xqi5UR_LiP.jpg?tr=w-671,h-671,fo-custom,cm-extract'
        // },
      ],
      gallery: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true,
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false,
      },
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    },
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.sw.refreshing) return;
        this.sw.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.overflow-fade-enter-active {
  transition: all 1s ease;
}
.overflow-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.overflow-fade-enter, .overflow-fade-leave-to {
  opacity: 0;
}
</style>
